
































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICompany } from '@/store/interfaces/company-model'
import { CompanyStore } from '@/store/modules/company'
import { RoutePaths } from '@/router/route-details'

@Component
export default class CompanyTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'business_name'
  stickyHeader = false
  paginated = false
  perPage = 20
  checkedRows = []

  // Methods
  onBindButtonClick(row: Record<string, any>) {
    this.$emit('bind-clicked', row)
  }
  onEditButtonClick(row: Record<string, any>) {
    CompanyStore.SET_CURRENT(row as ICompany)
    this.$router.push(RoutePaths.updateCompany(row.id))
  }
  onDeleteButtonClick(row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
}
