











































































import { Vue, Component } from 'vue-property-decorator'
import CompanyTable from './tables/CompanyTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { showInfoNotification } from '../../utils/notification'
import { CompanyStore } from '@/store/modules/company'
import { UserStore } from '@/store/modules/user'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    CompanyTable,
    ModalBox,
    DynamicSection,
  },
})
export default class Companies extends Vue {
  // Data
  isLoading = false
  searchString = ''
  filterString = ''
  isModalActive = false
  trashObject: Record<string, any> | null = null

  loadMoreEnabled = true
  dataSkip = 0
  dataLimit = 15

  // Computed Data
  get titleStack() {
    return ['Superuser', 'Aziende']
  }
  get titleBarButton() {
    return {
      label: "Aggiungi un'Azienda",
      icon: 'plus-circle',
      to: '/main/super/companies/add',
    } as ITitleBarButton
  }
  get companies() {
    if (this.filterString) {
      return CompanyStore.filteredData
    }
    return CompanyStore.data
  }
  get showLoadMoreButton() {
    return this.filterString ? false : this.loadMoreEnabled
  }
  get trashObjectName() {
    if (this.trashObject) {
      if (this.trashObject.business_name) return this.trashObject.business_name
      return this.trashObject.name + ' ' + this.trashObject.surname
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadCompanies()
  }

  // Methods
  async loadCompanies(): Promise<number | null> {
    this.isLoading = true
    const result = await CompanyStore.actionGetMulti({ skip: this.dataSkip, limit: this.dataLimit })
    if (result != null) {
      if (result < this.dataLimit) {
        this.loadMoreEnabled = false
      }
      this.dataSkip += result
    }
    this.isLoading = false
    return result
  }

  async refreshCompanies(notify = true) {
    this.isLoading = true
    let result
    if (this.filterString) {
      result = await CompanyStore.actionSearch(this.filterString)
    } else {
      let computedDataSkip = this.dataSkip
      if (!this.loadMoreEnabled) {
        computedDataSkip += this.dataLimit
      }
      result = await CompanyStore.actionGetMulti({ limit: computedDataSkip })
    }
    if (result && notify) showInfoNotification({ content: 'Aggiornato' })
    this.isLoading = false
  }
  async searchCompanies() {
    if (this.searchString) {
      this.isLoading = true
      const result = await CompanyStore.actionSearch(this.searchString)
      if (result) {
        this.filterString = this.searchString
        this.searchString = ''
        document.getElementById('searchField')?.blur()
      }
      this.isLoading = false
    }
  }
  resetSearchForm() {
    if (this.filterString) {
      this.isLoading = true
      this.searchString = ''
      this.filterString = ''
      this.isLoading = false
    }
  }

  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteCompany()
  }
  async deleteCompany() {
    if (this.trashObject) {
      const result = await CompanyStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.refreshCompanies(false)
      }
    }
  }

  async bindSuperUserToCompany(company: Record<string, any>) {
    const result = await UserStore.actionBindToCompany(company.id)
    if (result) {
      const businessName = company.business_name ? company.business_name : `${company.name} ${company.surname}`
      showInfoNotification({
        content: `Modalità "Assistenza Aziendale"<br>Adesso operi per conto di: ${businessName}`,
        duration: 5000,
      })
    }
  }
}
