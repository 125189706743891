




































































import { Vue, Component } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '../../utils/notification'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import { IUserProfileCreate, UserProfileUtils } from '@/store/interfaces/user-model'
import { SuperUserStore } from '@/store/modules/superuser'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    ValidationObserver,
    CardComponent,
    TitleBar,
    ModalBox,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
    DynamicSection,
  },
})
export default class AddSuperUser extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Data
  isModalActive = false
  form: IUserProfileCreate = UserProfileUtils.createInit()
  confirmPassword = ''

  // Computed Data
  get titleStack() {
    return ['Superuser', 'Utenti', 'Inserisci']
  }
  get titleBarButton() {
    return {
      label: 'Torna agli Utenti',
      icon: 'arrow-left',
      to: '/main/super/users',
    } as ITitleBarButton
  }

  // Methods
  async onSubmit() {
    const result = await SuperUserStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.form = UserProfileUtils.createInit()
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    showInfoNotification({ content: 'Campi azzerati' })
  }
  onCancel() {
    if (UserProfileUtils.createCheckHasChanged(this.form)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
