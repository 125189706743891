




















import { Vue, Component } from 'vue-property-decorator'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import CompanyForm from '@/views/forms/CompanyForm.vue'
import { ICompanyCreate, CompanyUtils } from '@/store/interfaces/company-model'
import { CompanyStore } from '@/store/modules/company'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    CompanyForm,
    DynamicSection,
  },
})
export default class AddCompany extends Vue {
  // Data
  isModalActive = false
  form: ICompanyCreate = CompanyUtils.createInit()

  // Computed Data
  get titleStack() {
    return ['Superuser', 'Aziende', 'Inserisci']
  }
  get titleBarButton() {
    return {
      label: 'Torna alle Aziende',
      icon: 'arrow-left',
      to: '/main/super/companies',
    } as ITitleBarButton
  }

  // Methods
  async onSubmit() {
    // Check if undefined field has been modified to empty string
    CompanyUtils.createCleanUndefined(this.form)
    const result = await CompanyStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.form = CompanyUtils.createInit()
  }
  onCancel() {
    if (CompanyUtils.createCheckHasChanged(this.form)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
