






























import { Vue, Component } from 'vue-property-decorator'
import SuperUserTable from './tables/SuperUserTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { SuperUserStore } from '@/store/modules/superuser'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    SuperUserTable,
    ModalBox,
    DynamicSection,
  },
})
export default class SuperUsers extends Vue {
  // Data
  isLoading = false
  isModalActive = false
  trashObject: Record<string, any> | null = null

  // Computed Data
  get titleStack() {
    return ['Superuser', 'Utenti']
  }
  get titleBarButton() {
    return {
      label: 'Aggiungi un Super User',
      icon: 'shield-plus',
      to: '/main/super/users/add',
    } as ITitleBarButton
  }
  get superUsers() {
    return SuperUserStore.data
  }
  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.full_name + ' (' + this.trashObject.email + ')'
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadSuperUsers()
  }

  // Methods
  async loadSuperUsers() {
    this.isLoading = true
    await SuperUserStore.actionGetAll()
    this.isLoading = false
  }
  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteSuperUser()
  }
  async deleteSuperUser() {
    if (this.trashObject) {
      const result = await SuperUserStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.loadSuperUsers()
      }
    }
  }
}
