



















import { Vue, Component, Prop } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import CompanyForm from '@/views/forms/CompanyForm.vue'
import { ISudoCompanyUpdate, CompanyUtils } from '@/store/interfaces/company-model'
import { CompanyStore } from '@/store/modules/company'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    CompanyForm,
    DynamicSection,
  },
})
export default class UpdateCompany extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isModalActive = false

  // Computed Data
  get titleStack() {
    return ['Superuser', 'Aziende', 'Modifica']
  }
  get company() {
    return CompanyStore.current
  }
  get form(): ISudoCompanyUpdate {
    return CompanyUtils.sudoUpdateInit(this.company)
  }

  // Vue Hooks
  async mounted() {
    await this.loadCompany()
  }

  // Methods
  async loadCompany() {
    if (this.company && this.company.id == this.id) return
    await CompanyStore.actionGet(this.id)
  }
  async onSubmit() {
    if (!this.company) return
    const diffObj = CompanyUtils.sudoUpdateGetDiff(this.form, this.company)
    const result = await CompanyStore.actionUpdate({ id: this.id, obj: diffObj })
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.company) return
    if (CompanyUtils.sudoUpdateCheckHasChanged(this.form, this.company)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
